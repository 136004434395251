<template>
  <!-- Header -->
  <header :class="['container flex-v-center header', { 'header--mobile': isMobileHeader }]">
    <div class="grid">
      <div class="span12 flex-v-center">
        <a href="/" title="Gå til top" data-gtm-action="header navigation" data-gtm-label="hjem">
          <inline-svg class="logo" :src="usePrefixAssetUrl(norliLogo)" width="150" height="30" aria-label="Norli logo"
        /></a>

        <nav class="d-flex flex-h-right nav">
          <ul :class="['d-flex flex-v-center menu', { 'menu--mobile': isMobileHeader, active: isMenuActive }]">
            <li v-for="(link, index) in props.links" :key="index">
              <a
                class="p-2"
                :href="`/${link.href}`"
                :title="link.title"
                data-gtm-action="header navigation"
                :data-gtm-label="link.title"
                v-text="link.title"
              />
            </li>
          </ul>
          <a
            class="button button--cta ml-4"
            href="/mit-norli"
            data-gtm-action="header navigation"
            data-gtm-label="log på"
            >Log på</a
          >
        </nav>

        <div v-if="isMobileHeader" class="flex-v-center ml-4" @click="store.commit('toggleMenu', !isMenuActive)">
          <hamburger :is-menu-active="isMenuActive" gtm-action-cat="header navigation" />
        </div>

        <teleport to="#app">
          <transition name="fade">
            <div
              v-if="(isMobileHeader && isMenuActive) || isModalActive"
              class="overlay"
              @click="handleOverlayClick()"
            />
          </transition>
        </teleport>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import norliLogo from '@/assets/svg/norli-logo.svg'
import Hamburger from '@/components/Hamburger/Hamburger.vue'

import { usePrefixAssetUrl } from '@/composables/usePrefixAssetUrl'
import { computed } from 'vue'
import { useStore } from 'vuex'

interface IProps {
  links?: {
    title: string
    href: string
  }[]
}

const store = useStore()
const props = defineProps<IProps>()

const isMobileHeader = computed(() => {
  return store.state.windowWidth < 920
})

const isMenuActive = computed(() => {
  return store.state.isMenuOpen
})

const isModalActive = computed(() => {
  return store.state.isModalOpen
})

const handleOverlayClick = () => {
  if (store.state.isMenuOpen) store.commit('toggleMenu', false)
  if (store.state.isModalOpen) store.commit('toggleModal', false)
}
</script>

<style scoped></style>
