<template>
  <subsite-header :links="navLinks" />
  <div class="grid" style="align-self: center">
    <div class="span8 move2 span10--md move1--md my-7 pt-6">
      <div class="image-text-overlay">
        <SubsiteTitle text="Velkommen til Norli Liv og Pension" />
        <img :src="usePrefixAssetUrl(heroBg)" alt="hero image" />
      </div>
      <p class="mt-1">
        Den 1. januar 2025 overtog vi Norli Pensions kunder med traditionelle pensionsaftaler. Det betyder, at du som
        tidligere kunde i Norli Pension nu er kunde i Norli Liv og Pension.
      </p>
      <p>
        At din ordning er flyttet over til os, betyder helt konkret, at du som kunde fortsat kan føle dig tryg i et
        selskab, som specialiserer sig i lige netop det produkt, du har. Vi laver nemlig ikke andet, og derfor er vores
        fulde fokus på de garanterede produkter.
      </p>
      <SubsiteSubTitle text="Find os på norli.dk" />
      <p>På norli.dk finder du alle de informationer, som kan være relevante for dig som kunde.</p>
      <p>Her finder du også Mit Norli, som er dit selvbetjeningsoverblik. I Mit Norli kan du bl.a.:</p>
      <ul>
        <li>Se din garanterede og forventede pensionsudbetaling</li>
        <li>Få overblik over dine forsikringsdækninger</li>
        <li>Læse den post, vi sender til dig</li>
        <li>Tilføje og rette dine kontaktoplysninger.</li>
      </ul>
      <a href="https://www.norli.dk" class="link-button">Gå til forsiden på norli.dk</a>
      <SubsiteSubTitle class="mt-4" text="Dine forsikringsbetingelser" />
      <p>
        Vi har gjort det nemt for dig at finde dine forsikringsbetingelser – du finder dem nemlig lige herunder.
        Fremover vil du også kunne finde dem på norli.dk-forsiden ved at scrolle ned til afsnittet ’Priser, vilkår og
        blanketter’ og herefter klikke på ’Historiske betingelser’.
      </p>
      <details>
        <summary>Forsikringsbetingelser</summary>
        <ul class="conditions-list">
          <li v-for="condition in conditions">
            <a :href="condition.link" target="_blank">{{ condition.label }}</a>
          </li>
        </ul>
      </details>
    </div>
  </div>
  <FooterComponent />
</template>

<script setup lang="ts">
import heroBg from '@/assets/img/hero-bg-alt2-min.jpg'
import FooterComponent from '@/components/Footer/Footer.vue'
import SubsiteHeader from '@/components/Subsites/SubsiteHeader.vue'
import SubsiteTitle from '@/components/Subsites/SubsiteTitle.vue'
import SubsiteSubTitle from '@/components/Subsites/SubsiteSubTitle.vue'
import { usePrefixAssetUrl } from '@/composables/usePrefixAssetUrl'

const navLinks = [
  {
    title: 'Vores løsninger',
    href: '#loesninger',
  },
  {
    title: 'Spørgsmål og svar',
    href: '#faq',
  },
  {
    title: 'Om os',
    href: '#om',
  },
  {
    title: 'For pensionsbranchen',
    href: '#pensions',
  },
  {
    title: 'Kontakt',
    href: '#kontakt',
  },
]

const baseFileUrl = 'siteassets/site-images/pdf/norli_pension/betingelser/'

const conditions = [
  { label: 'Norli Pension 10 (Kgl. Brand)', link: baseFileUrl + '10_Norlipension_A_betingelser.pdf' },
  { label: 'Norli Pension 11 (Skandia)', link: baseFileUrl + '11_Norlipension_A_betingelser.pdf' },
  { label: 'Norli Pension 12 (Skandia)', link: baseFileUrl + '12_Norlipension_A_betingelser.pdf' },
  { label: 'Norli Pension 13 (Skandia)', link: baseFileUrl + '13_Norlipension_A_betingelser.pdf' },
  {
    label: 'Norli Pension 92-10 Livrenter uden ret til bonus (Kgl. Brand)',
    link: baseFileUrl + '92-10_Livrenter_u_bonusret_Norlipension_betingelser.pdf',
  },
  {
    label: 'Norli Pension 94-14 Bonuspension (Skandia)',
    link: baseFileUrl + '94-14_Norlipension_bonuspension_betingelser.pdf',
  },
  {
    label: 'Norli Pension 01-15 Kristisk sygdom (Skandia)',
    link: baseFileUrl + '01-15_Kritisksygdom_Norlipension_betingelser.pdf',
  },
  { label: 'Liste over kritiske sygdomme', link: baseFileUrl + 'Kritiskesygdomme_Norlipension.pdf' },
  { label: 'Norli Pension Gruppeliv', link: baseFileUrl + 'Gruppeliv_FG_Norlipension_betingelser_2012.pdf' },
]
</script>

<style lang="scss" scoped>
@import '../../assets/scss/imports/colors';

.image-text-overlay {
  position: relative;
  width: fit-content;

  & > :first-child {
    position: absolute;
    color: white;
    z-index: 1;
    top: 2em;
    left: 3%;
  }

  & > img {
    max-height: 100%;
    max-width: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
}

.link-button {
  padding: 0.1em 1.5em 1em 0.5em;
  color: $color-white;
  background-color: $color-primary;
  text-decoration: none;
}

summary {
  cursor: pointer;
}

.conditions-list {
  list-style-type: none !important;
  background-color: $color-grey-100;
  width: fit-content;
  padding-inline: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  & > li {
    padding-left: 0;

    &::before {
      display: none;
    }

    & > a {
      text-decoration: none;
    }
  }
}
</style>
