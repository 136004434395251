<template>
  <div class="new-customer__banner container p-3">
    <div class="grid">
      <div class="span12 d-flex">
        Er du tidligere kunde i Norli Pension? Så kan du
        <a class="new-customer__underline-text pl-1" :href="link"
          >læse mere om overdragelsen af din pensionsordning her.</a
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const link = window.location.href + 'norlipension'
</script>
